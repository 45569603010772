import React from 'react';
import Layout from '../components/layout';
import {graphql} from "gatsby";
import {PostTitle} from "../components/post-details/post-details.style";
import {PostContent} from "../components/post-card/post-card.style";
import {BlogDetailsContent, BlogPostDetailsWrapper} from "./templates.style";


interface WpPage{
    title:string;
    content:string;
}

const BlogPageTemplate  = (props: any) => {
    const data = props.data.wordpressPage as WpPage;
  return (
    <Layout>
        <BlogPostDetailsWrapper>
            <BlogDetailsContent>
        <PostTitle>
            {data.title}
        </PostTitle>
        <PostContent>
            <div dangerouslySetInnerHTML={{__html:data.content}}/>
        </PostContent>
            </BlogDetailsContent>
        </BlogPostDetailsWrapper>





    </Layout>
  );
};

export default BlogPageTemplate;
export const pageQuery = graphql`
 
  query BlogPageByID($id: String!) {
   
    wordpressPage(id: { eq: $id }) {
      id
      title
      slug
      content     
    }
  }
`
